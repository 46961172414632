import { BBUIPush, bigbearappsTheme } from '@bigbearui/index'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import { useRouter } from 'next/router'

interface Props {
    setPage: (page: string) => void
}

const BigBearAccountPage: React.FC<Props> = ({ setPage }) => {
    const router = useRouter()
    return (
        <>
            <CommandGroup heading="Menu">
                <CommandItem
                    onSelect={value => {
                        setPage('')
                    }}>
                    <span>Back</span>
                </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="BigBearAccount Menu">
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount',
                            router,
                        })
                    }}>
                    <span>BigBearAccount Home</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/profile',
                            router,
                        })
                    }}>
                    <span>Edit Profile</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/password',
                            router,
                        })
                    }}>
                    <span>Change Password</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/two-factor',
                            router,
                        })
                    }}>
                    <span>Two Factor</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/authentication-log',
                            router,
                        })
                    }}>
                    <span>Authentication Log</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/delete-profiles',
                            router,
                        })
                    }}>
                    <span>Delete Profiles</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearaccount/delete-account',
                            router,
                        })
                    }}>
                    <span>Delete Account</span>
                </CommandItem>
            </CommandGroup>
        </>
    )
}

export default BigBearAccountPage
