import { BBUIPush, BBUITopbarHome } from '@bigbearui/index'

import HomeHeaderWelcome from './home-header-welcome'
import React from 'react'

interface Props {
    componentId?: string
}

const HomeHeader: React.FC<Props> = ({ componentId = '' }) => {
    return (
        <>
            <BBUITopbarHome title={'BigBearApps'} />
            <HomeHeaderWelcome />
        </>
    )
}

export default HomeHeader
