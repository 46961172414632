import { BBUIPush, bigbearappsTheme } from '@bigbearui/index'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import BigBearAccountPage from './Pages/BigBearAccountCommandPage'
import EmailcoveCommandPage from './Pages/EmailcoveCommandPage'
import MainCommandPage from './Pages/MainCommandPage'
import React from 'react'
import { useRouter } from 'next/router'

const BigBearAppsCommandMenu = () => {
    const router = useRouter()
    const [open, setOpen] = React.useState(false)
    const [page, setPage] = React.useState('')

    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                console.log('hi')
                e.preventDefault()
                setOpen(open => !open)
            }
        }
        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    return (
        <CommandDialog open={open} onOpenChange={setOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                {page === '' && <MainCommandPage setPage={setPage} />}
                {page === 'bigbearaccount' && (
                    <BigBearAccountPage setPage={setPage} />
                )}
                {page === 'emailcove' && (
                    <EmailcoveCommandPage setPage={setPage} />
                )}
            </CommandList>
        </CommandDialog>
    )
}

export default BigBearAppsCommandMenu
