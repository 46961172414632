import {
    BBUIAlertModal,
    BBUIContentHome,
    BBUIContentHomeItem,
    BBUIContentHomeItemSeparator,
    BBUIFooterHome,
    BBUILogout,
    BBUIPage,
    BBUIPush,
    useBBUINiceModalStore,
} from '@bigbearui/index'

import BBUISidenavFull from '../../../lib/webui/bbui-sidenav-apps/bbui-sidenav-full'
import HomeAddons from './home-addons'
import HomeHeader from './home-header'
import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

const BBUISpacer = styled(View)({
    padding: 10,
})

interface Props {
    componentId?: string
}

const HomeScreen: React.FC<Props> = ({ componentId = '' }) => {
    const router = useRouter()
    const queryCache = useQueryClient()
    const { addModal } = useBBUINiceModalStore()
    return (
        <HomeAddons componentId={componentId}>
            <BBUIPage testID="scrollView" isScrollable>
                {({}) => {
                    return (
                        <>
                            <HomeHeader componentId={componentId} />

                            <BBUISpacer />

                            <BBUIContentHome>
                                <BBUIContentHomeItemSeparator title="Apps" />
                                <BBUIContentHomeItem
                                    testID="my-account"
                                    icon="user"
                                    title="My Account"
                                    description="Manage your BigBearAccount"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/bigbearaccount',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="doorknockerpal"
                                    icon="hand-rock"
                                    title="DoorKnockerPal"
                                    description="DoorKnockerPal makes it easy for you to keep up with your potential clients when you or your team knocks on doors!"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/doorknockerpal',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="boxinvy"
                                    icon="boxes"
                                    title="Boxinvy"
                                    description="Boxinvy helps you inventory your House, Apartment, Business, RV, and more..."
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/boxinvy',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="mealinvy"
                                    icon="utensils"
                                    title="Mealinvy"
                                    description="Mealinvy helps you track your meals. Review restaurants and employees."
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/mealinvy',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="bigbearhealth"
                                    icon="heartbeat"
                                    title="BigBearHealth"
                                    description="Health Tracking Made Simple"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/bigbearhealth',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="reviewpal"
                                    icon="star"
                                    title="ReviewPal"
                                    description="ReviewPal helps you keep track of your reviews"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/reviewpal',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="goalpense"
                                    icon="hand-holding-usd"
                                    title="Goalpense"
                                    description="Goals and Finances."
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/goalpense',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItemSeparator title="Beta" />
                                <BBUIContentHomeItem
                                    testID="panelbay"
                                    icon="solar-panel"
                                    title="Panelbay"
                                    description="Inventory Electric Panels or Networking ETC..."
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/panelbay',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="emailcove"
                                    icon="mail-bulk"
                                    title="Emailcove"
                                    description="EmailCove protects your email from spammers with ease!"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/emailcove',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="bigbearmoments"
                                    icon="lightbulb"
                                    title="BigBearMoments"
                                    description="Log your moments with people"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/bigbearmoments',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="wealtor"
                                    icon="home"
                                    title="Wealtor"
                                    description="Your personal assistant for all your real estate needs"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/wealtor',
                                            router,
                                        })
                                    }}
                                />
                                <BBUIContentHomeItemSeparator title="Settings" />
                                <BBUIContentHomeItem
                                    testID="chat-with-us"
                                    icon="comments"
                                    title="Support"
                                    description="Chat with us on our community forum"
                                    onPress={() => {
                                        ;(window as any).open(
                                            'https://community.bigbeartechworld.com?app=bigbearapps&where=web',
                                            '_blank',
                                        )
                                    }}
                                />
                                <BBUIContentHomeItem
                                    testID="logout"
                                    icon="sign-out-alt"
                                    title="Logout"
                                    description="Logout of BigBearApps"
                                    onPress={() => {
                                        BBUIPush({
                                            path: BBUIAlertModal,
                                            isModal: true,
                                            addModal,
                                            router,
                                            state: {
                                                title: 'Are you sure?',
                                                message: 'You want to logout?',
                                                buttons: [
                                                    {
                                                        testID: 'cancel-button',
                                                        text: 'Cancel',
                                                        onPress: () => {},
                                                        style: 'cancel',
                                                    },
                                                    {
                                                        testID: 'yes-button',
                                                        text: 'Yes',
                                                        onPress: () => {
                                                            BBUILogout({
                                                                queryCache,
                                                            })
                                                            window.location.href = `/accounts/login?redirect=${window.location.href}`
                                                        },
                                                    },
                                                ],
                                                options: { cancelable: false },
                                            },
                                        })
                                    }}
                                />
                            </BBUIContentHome>

                            <BBUIFooterHome
                                title={process.env.APP_NAME}
                                version={process.env.APP_VERSION}
                            />
                        </>
                    )
                }}
            </BBUIPage>
        </HomeAddons>
    )
}

export default HomeScreen
