import { BBUIAxiosQuery, BBUIWelcomeHome } from '@bigbearui/index'

import { BigBearAccountsPaths } from '../../../lib/di'
import React from 'react'
import { useQuery } from '@tanstack/react-query'

interface Props {}

const HomeHeaderWelcome: React.FC<Props> = ({}) => {
    const { isLoading, isError, data, error } = useQuery<
        BigBearAccountsPaths['/bigbearaccounts/api/v1/me']['get']['responses']['200']['application/json']['data']['data'],
        Error
    >(['me'], async () => {
        const response: BigBearAccountsPaths['/bigbearaccounts/api/v1/me']['get']['responses']['200']['application/json'] = await BBUIAxiosQuery(
            {
                url: `/bigbearaccounts/api/v1/me`,
                params: {},
                method: 'GET',
            },
        )
        return response.data.data
    })

    if (isLoading) {
        return <BBUIWelcomeHome name={'Loading'} />
    }

    if (isError) {
        return <BBUIWelcomeHome name={'Error Loading'} />
    }

    return (
        <>
            <BBUIWelcomeHome name={data.item.name} />
        </>
    )
}

export default HomeHeaderWelcome
