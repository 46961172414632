import { AppShell, Navbar } from '@mantine/core'
import { BBUIPush, bigbearappsTheme } from '@bigbearui/index'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import { BBUISidenavFull } from '../../lib/webui'
import BigBearAccountPage from '../CommandMenus/BigBearApps/Pages/BigBearAccountCommandPage'
import BigBearAppsCommandMenu from '../CommandMenus/BigBearApps/BigBearAppsCommandMenu'
import { DripsyProvider } from 'dripsy'
import Head from 'next/head'
import MainCommandPage from '../CommandMenus/BigBearApps/Pages/MainCommandPage'
import Modals from '../modals'
import React from 'react'
import { TooltipProvider } from '@/components/ui/tooltip'
import { useAuth } from '../../hooks/auth'
import { useRouter } from 'next/router'

const BigBearAppsLayout = ({ children }) => {
    const { user } = useAuth({ middleware: 'auth' })

    return (
        <>
            <Head>
                <link
                    rel="shortcut icon"
                    href="/favicons/bigbeartechworld/favicon.ico?v=yyajQO0Rqm"
                />
                <meta name="apple-mobile-web-app-title" content="BigBearApps" />
                <meta name="application-name" content="BigBearApps" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#5A1E2F" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/favicons/bigbeartechworld/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicons/bigbeartechworld/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicons/bigbeartechworld/favicon-16x16.png"
                />
                <link
                    rel="manifest"
                    href="/favicons/bigbeartechworld/site.webmanifest"
                />
            </Head>
            <DripsyProvider
                ssr
                ssrPlaceholder={null} // optional
                theme={bigbearappsTheme}>
                <AppShell
                    padding="none"
                    fixed={true}
                    navbar={
                        <Navbar
                            width={{
                                65: 65,
                                xs: 65,
                                sm: 65,
                                lg: 65,
                            }}>
                            <BBUISidenavFull activeApp="bigbearapps" />
                        </Navbar>
                    }>
                    <div
                        style={{
                            marginLeft: 65,
                        }}
                        id="bigbearapps-layout">
                        {children}
                    </div>
                </AppShell>
                <BigBearAppsCommandMenu />
                <Modals />
            </DripsyProvider>
        </>
    )
}

export default BigBearAppsLayout
