import BigBearAppsLayout from '../components/Layouts/BigBearAppsLayout'
import Head from 'next/head'
import HomeScreen from '../components/BigBearApps/home/home-screen'
import React from 'react'
import { useAuth } from '../hooks/auth'

export default function Home() {
    const { user } = useAuth({ middleware: 'auth' })

    return (
        <>
            <Head>
                <title>BigBearApps</title>

                <link
                    rel="shortcut icon"
                    href="/favicons/bigbeartechworld/favicon.ico?v=yyajQO0Rqm"
                />
                <meta name="apple-mobile-web-app-title" content="BigBearApps" />
                <meta name="application-name" content="BigBearApps" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#5A1E2F" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/favicons/bigbeartechworld/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicons/bigbeartechworld/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicons/bigbeartechworld/favicon-16x16.png"
                />
                <link
                    rel="manifest"
                    href="/favicons/bigbeartechworld/site.webmanifest"
                />
            </Head>

            <BigBearAppsLayout>
                <HomeScreen />
            </BigBearAppsLayout>
        </>
    )
}
