import { BBUIPush, bigbearappsTheme } from '@bigbearui/index'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import router from 'next/router'

interface Props {
    setPage: (page: string) => void
}

const MainCommandPage: React.FC<Props> = ({ setPage }) => {
    return (
        <>
            <CommandGroup heading="Apps">
                <CommandItem
                    onSelect={value => {
                        setPage('bigbearaccount')
                    }}>
                    <span>BigBearAccount</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/doorknockerpal',
                            router,
                        })
                    }}>
                    <span>DoorknockerPal</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/boxinvy',
                            router,
                        })
                    }}>
                    <span>Boxinvy</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/mealinvy',
                            router,
                        })
                    }}>
                    <span>Mealinvy</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearhealth',
                            router,
                        })
                    }}>
                    <span>BigBearHealth</span>
                </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Beta Apps">
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/panelbay',
                            router,
                        })
                    }}>
                    <span>Panelbay</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        setPage('emailcove')
                    }}>
                    <span>Emailcove</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/bigbearmoments',
                            router,
                        })
                    }}>
                    <span>BigBearMoments</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/goalpense',
                            router,
                        })
                    }}>
                    <span>Goalpense</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/wealtor',
                            router,
                        })
                    }}>
                    <span>Wealtor</span>
                </CommandItem>
            </CommandGroup>
        </>
    )
}

export default MainCommandPage
