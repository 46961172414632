import { BBUIPush, bigbearappsTheme } from '@bigbearui/index'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import router from 'next/router'

interface Props {
    setPage: (page: string) => void
}

const EmailcoveCommandPage: React.FC<Props> = ({ setPage }) => {
    return (
        <>
            <CommandGroup heading="Menu">
                <CommandItem
                    onSelect={value => {
                        setPage('')
                    }}>
                    <span>Back</span>
                </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Quick Actions">
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: `/emailcove/coves/create`,
                            state: {},
                            router,
                        })
                    }}>
                    <span>Create Cove</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: `/emailcove/conversations/create`,
                            state: {},
                            router,
                        })
                    }}>
                    <span>Create Conversation</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: `/emailcove/destinations/create`,
                            state: {},
                            router,
                        })
                    }}>
                    <span>Create Destination</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: `/emailcove/namespaces/create`,
                            state: {},
                            router,
                        })
                    }}>
                    <span>Reserve Namespace</span>
                </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Emailcove Menu">
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/emailcove/coves',
                            router,
                        })
                    }}>
                    <span>Coves</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/emailcove/conversations',
                            router,
                        })
                    }}>
                    <span>Conversations</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/emailcove/destinations',
                            router,
                        })
                    }}>
                    <span>Destinations</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/emailcove/namespaces',
                            router,
                        })
                    }}>
                    <span>Namespaces</span>
                </CommandItem>
                <CommandItem
                    onSelect={value => {
                        BBUIPush({
                            componentId: '',
                            path: '/emailcove/emails',
                            router,
                        })
                    }}>
                    <span>Emails</span>
                </CommandItem>
            </CommandGroup>
        </>
    )
}

export default EmailcoveCommandPage
